export const GetPropsMenu = (path) => {
  var defaultSelectedKeys = "1";
  switch (path) {
    case "/app/client/managerial":
      defaultSelectedKeys = "1";
      break;
    case "/app/client/reviews":
      defaultSelectedKeys = "2";
      break;

    case "/app/client/revenues":
      defaultSelectedKeys = "4";
      break;
    case "/app/client/expenses":
      defaultSelectedKeys = "5";
      break;
    case "/app/client/users":
      defaultSelectedKeys = "6";
      break;
    case "/app/client/cash-flow-month":
      defaultSelectedKeys = "7";
      break;
    case "/app/client/income-statement-v2":
      defaultSelectedKeys = "8";
      break;
    case "/app/client/bills-pay":
      defaultSelectedKeys = "9";
      break;
    case "/app/client/bills-receive":
      defaultSelectedKeys = "10";
      break;
    case "/app/client/cost-center":
      defaultSelectedKeys = "101";
      break;
    case "/app/admin/client":
      defaultSelectedKeys = "1";
      break;
    case "/app/admin/user":
      defaultSelectedKeys = "2";
      break;
    case "/app/admin/category":
      defaultSelectedKeys = "22";
      break;
    case "/app/admin/mapping-cash-flow":
      defaultSelectedKeys = "235";
      break;
    case "/app/admin/mapping-income":
      defaultSelectedKeys = "231";
      break;
    case "/app/admin/import":
      defaultSelectedKeys = "4";
      break;
    case "/app/admin/cash-flow":
      defaultSelectedKeys = "111";
      break;
    case "/app/admin/income":
      defaultSelectedKeys = "211";
      break;
    case "/app/client/ranking":
      defaultSelectedKeys = "7";
      break;
    default:
      break;
  }
  return { defaultSelectedKeys };
};
