import React, { useEffect, useState } from "react";
import { Spin, Row, Space, Card, Table, Typography, Select, Form } from "antd";
import { Medal } from "lucide-react";
import { FONT, ROLE_USER, USER_STORAGE } from "constants/index";
import { notificationErroInternal } from "util/api-utils";
import { ColumnsUser } from "./columns-table";
import { getUsersReviewsRanking } from "services/review/review-service";
import { useSelector } from "react-redux";
import { selectRole } from "reducer/login/login-user";

const { Text } = Typography;

const RankingClient = () => {
  const [loading, setLoading] = useState(true);
  const [rangeAge, setRangeAge] = useState("GENERAL");
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [roleRedux, setRoleRedux] = useState(useSelector(selectRole));
  const [idUser, setIdUser] = useState(null);

  useEffect(() => {
    refreshData(rangeAge);
    form.setFieldsValue({
      rangeAnge: rangeAge,
    });
  }, []);

  useEffect(() => {
    const localStorageItem = localStorage.getItem(USER_STORAGE);
    if (localStorageItem != null) {
      const { role, id } = JSON.parse(localStorageItem);
      if (roleRedux == "" || role != roleRedux) {
        setRoleRedux(role);
      }
      setIdUser(id);

    }
  }, []);

  function refreshData(rangeAge) {
    setLoading(true);
    setRangeAge(rangeAge);
    getData(rangeAge);
  }

  function getData(rangeAge) {
    let obj = {
      rangeAge: rangeAge,
    };

    if (rangeAge == null || rangeAge == "") {
      obj = {};
    }
    getUsersReviewsRanking(obj)
      .then((res) => {
        res = res.map((obj) => ({
          ...obj,
        }));
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  return (
    <div className="container-ranking-client">
      <Spin size="large" spinning={loading}>
        {roleRedux != ROLE_USER && (
          <Row gutter={24} style={{ padding: "10px", marginBottom: "-20px" }}>
            <Space>
              <Form form={form}>
                <Form.Item name="rangeAnge">
                  <Select
                    placeholder="Faixa Etária"
                    onChange={(event) => {
                      refreshData(event);
                    }}
                    style={{ width: "240px" }}
                  >
                    <Option value="GENERAL">Geral</Option>
                    <Option value="ELEVEN_MINUS"> {"<11 anos"} </Option>
                    <Option value="TWELVE_FOURTEEN">12 a 14 anos</Option>
                    <Option value="FIFTEEN_EIGHTEEN">15 a 18 anos </Option>
                    <Option value="NINETEEN_PLUS">{">19 anos"}</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        )}
        <Row gutter={[24, 24]} style={{ marginLeft: "0px" }}>
          <Space
            direction="vertical"
            size={"small"}
            style={{
              flex: "1 1 500px",
              minWidth: outerWidth < 501 ? "0px" : "500px",
            }}
          >
            <Card
              id="card-table"
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <Medal
                      size={18}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                      Classificação dos Alunos
                    </Text>
                  </div>
                </div>
              }
              bordered={false}
            >
              <Table
                columns={ColumnsUser()}
                dataSource={data}
                pagination={{ pageSize: 10, showSizeChanger: false }}
                locale={{
                  emptyText: "Sem registro",
                }}
                rowClassName={(record) => {
                  console.log(record)
                  console.log(idUser)
                  console.log(roleRedux)

                return  record.id === idUser && roleRedux == ROLE_USER
                    ? "highlight-row"
                    : "";
                }}
              />
            </Card>
          </Space>
        </Row>
      </Spin>
    </div>
  );
};
export default RankingClient;
