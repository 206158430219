import {
  Users,
  Gauge,
  ClipboardCheck,
  Calendar,
  Dumbbell,
  Trophy,
} from "lucide-react";

export const GetPropsHeader = (path) => {
  var pathName = "";
  var pathIcon = "";
  switch (path) {
    case "/app/client/managerial":
      pathName = "Painel Gerencial";
      pathIcon = (
        <Gauge
          color="#9FA1AA"
          size={15}
          style={{ marginBottom: "-2px", marginRight: "5px" }}
        />
      );
      break;
    case "/app/client/reviews":
      pathName = "Avaliações";
      pathIcon = (
        <ClipboardCheck
          color="#9FA1AA"
          size={15}
          style={{ marginBottom: "-2px", marginRight: "5px" }}
        />
      );
      break;
    case "/app/client/revenues":
      pathName = "Planificação";
      pathIcon = (
        <Calendar
          color="#9FA1AA"
          size={15}
          style={{ marginBottom: "-2px", marginRight: "5px" }}
        />
      );
      break;
    case "/app/client/expenses":
      pathName = "Controle de Cargas";
      pathIcon = (
        <Dumbbell
          color="#9FA1AA"
          size={15}
          style={{ marginBottom: "-2px", marginRight: "5px" }}
        />
      );
      break;
    case "/app/client/users":
      pathName = "Usuários";
      pathIcon = (
        <Users
          color="#9FA1AA"
          size={15}
          style={{ marginBottom: "-2px", marginRight: "5px" }}
        />
      );
      break;
    case "/app/client/ranking":
      pathName = "Classificação";
      pathIcon = (
        <Trophy
          color="#9FA1AA"
          size={15}
          style={{ marginBottom: "-2px", marginRight: "5px" }}
        />
      );
      break;
    default:
      break;
  }
  return { pathName, pathIcon };
};
