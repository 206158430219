import React, { useEffect, useState } from "react";

import {
  Row,
  Input,
  Typography,
  Form,
  Col,
  DatePicker,
  InputNumber,
  Divider,
  Avatar,
  Space,
  Card,
  Table,
  Spin,
  Modal,
} from "antd";
import {
  ArrowUpFromLine,
  BookUp2,
  CircleAlert,
  Medal,
  UserRound,
} from "lucide-react";
import { FONT } from "constants/index";
import { ColumnsUserComparation } from "./columns-table";
import { chartBar, chartLiner } from "./reviews-drawer-charts";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DrawerApp from "components/drawer-app";
import { formNewUserReview } from "./reviews-drawer-new-review";
import {
  getReviewUserById,
  putReview,
  deleteReview,
} from "services/review/review-service";
import { notificationErroInternal, notificationSucess } from "util/api-utils";
import moment from "moment";
dayjs.extend(customParseFormat);
const { Text } = Typography;
const dateFormat = "DD/MM/YYYY";
const { confirm } = Modal;
const FormUserComparation = (props) => {
  const [data, setDataDrawer] = useState({ reviews: [] });
  const [imgAvatar, setImgAvatar] = useState(null);
  const formRefComp = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const formRef = React.useRef(null);
  const [reviewCa, setReviewCa] = useState(false);
  const [editId, setEditId] = useState(null);
  const [reviewCvNew, setReviewCvNew] = useState(false);

  useEffect(() => {
    const data = props.dataDrawer;
    data.reviews = data?.reviews?.map((obj) => ({
      ...obj,
      changeActualComp: changeActual,
      changeLastComp: changeLast,
      showDrawer: showDrawer,
      delete: deleteReviewItem,
    }));
    setDataDrawer(data);

    setImgAvatar(props.imgAvatar);
    formRefComp.current?.setFieldsValue({
      name: props.dataDrawer.name,
      reviewActual: props.dataDrawer?.actualReview?.reviewDate
        ? dayjs(props.dataDrawer?.actualReview?.reviewDate)
        : null,
      reviewComparation: props.dataDrawer?.lastReview?.reviewDate
        ? dayjs(props.dataDrawer?.lastReview?.reviewDate)
        : null,
      weight: props.dataDrawer?.actualReview?.weight,
      weightComparation: props.dataDrawer?.lastReview?.weight,
    });
  }, [props, setLoading]);

  const changeActual = function (row, data) {
    setLoading(true);
    setTimeout(() => {
      data.actualReview = row;
      formRefComp.current?.setFieldsValue({
        reviewActual: row.reviewDate ? dayjs(row.reviewDate) : null,
        weight: row.weight,
      });
      setDataDrawer(data);
      setLoading(false);
    }, 300);
  };

  const changeLast = function (row, data) {
    setLoading(true);
    setTimeout(() => {
      data.lastReview = row;
      formRefComp.current?.setFieldsValue({
        reviewComparation: row.reviewDate ? dayjs(row.reviewDate) : null,
        weightComparation: row.weight,
      });

      setDataDrawer(data);
      setLoading(false);
    }, 300);
  };
  const showDrawer = (row) => {
    setLoading(true);
    setTimeout(() => {
      setEditId(row.id);
      setReviewCa(row.load1Ca != null);
      setReviewCvNew(row.load1CvNew != null);

      formRef.current?.setFieldsValue({
        name: props.dataDrawer.name,
        weight: row.weight,
        date: row.reviewDate ? dayjs(row.reviewDate) : null,
        cmj: row.cmj,
        sj: row.sj,
        dj: row.dj,
        djBox: row.djBox,
        load1: row.load1,
        load2: row.load2,
        load3: row.load3,
        load4: row.load4,
        load5: row.load5,
        load6: row.load6,
        load1Ca: row.load1Ca,
        load2Ca: row.load2Ca,
        load3Ca: row.load3Ca,
        load4Ca: row.load4Ca,
        load5Ca: row.load5Ca,
        load6Ca: row.load6Ca,
        load1CvNew: row.load1CvNew,
        load2CvNew: row.load2CvNew,
        load3CvNew: row.load3CvNew,
        load4CvNew: row.load4CvNew,
        load5CvNew: row.load5CvNew,
        load6CvNew: row.load6CvNew,
        vmp1: row.vmp1,
        vmp2: row.vmp2,
        vmp3: row.vmp3,
        vmp4: row.vmp4,
        vmp5: row.vmp5,
        vmp6: row.vmp6,
        vmp1Ca: row.vmp1Ca,
        vmp2Ca: row.vmp2Ca,
        vmp3Ca: row.vmp3Ca,
        vmp4Ca: row.vmp4Ca,
        vmp5Ca: row.vmp5Ca,
        vmp6Ca: row.vmp6Ca,
        vmp1CvNew: row.vmp1CvNew,
        vmp2CvNew: row.vmp2CvNew,
        vmp3CvNew: row.vmp3CvNew,
        vmp4CvNew: row.vmp4CvNew,
        vmp5CvNew: row.vmp5CvNew,
        vmp6CvNew: row.vmp6CvNew,
        nameCvNew: row.nameCvNew,
      });
      setLoading(false);
    }, 300);
    setOpen(true);
  };
  const onClose = () => {
    formRef.current?.resetFields();
    setEditId(null);
    setOpen(false);
  };
  const handleEditReview = () => {
    setLoading(true);
    formRef?.current
      ?.validateFields()
      .then((values) => {
        values.id = editId;
        values.userId = props.dataDrawer.id;
        values.djBox = !values.djBox ? 30 : values.djBox;
        values.date = values.date.format(dateFormat);
        putReview(values)
          .then(() => {
            getData();
          })
          .catch(() => {
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
            setLoading(false);
          });
      })
      .catch((errors) => {
        setLoading(false);
        console.log(errors);
      });
  };
  function getData() {
    getReviewUserById(props.dataDrawer.id)
      .then((res) => {
        res.reviews = res?.reviews?.map((obj) => ({
          ...obj,
          changeActualComp: changeActual,
          changeLastComp: changeLast,
          showDrawer: showDrawer,
          delete: deleteReviewItem,
        }));
        setDataDrawer(res);
        formRefComp.current?.setFieldsValue({
          name: res.name,
          reviewActual: res.actualReview?.reviewDate
            ? dayjs(res.actualReview?.reviewDate)
            : null,
          reviewComparation: res.lastReview?.reviewDate
            ? dayjs(res.lastReview?.reviewDate)
            : null,
          weight: res.actualReview?.weight,
          weightComparation: res.lastReview?.weight,
        });
        setLoading(false);
        notificationSucess();
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  function deleteReviewItem(row) {
    confirm({
      title: (
        <div>
          <CircleAlert size={22} style={{ marginBottom: "-5px" }} />
          <span style={{ marginLeft: "10px", fontFamily: FONT }}>
            Tem certeza que deseja deletar a avaliação do dia '
            {moment(row.reviewDate).format("DD/MM/YYYY")}' ?
          </span>
        </div>
      ),
      icon: null,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        setLoading(true);
        deleteReview(row.id)
          .then(() => {
            getData();
          })
          .catch((err) => {
            setLoading(false);
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      },
      onCancel() {},
    });
  }
  function levelRquad(point) {
    if (point >= 0.98 && point <= 1) {
      return " - Excelente";
    }
    if ((point >= 0.96 && point <= 0, 97)) {
      return " - Moderado";
    }
    if ((point <= 0, 95)) {
      return " - Ruim";
    }
    return "";
  }
  return (
    <>
      <Spin size="large" spinning={loading}>
        <Form ref={formRefComp} layout="vertical">
          <Row
            gutter={16}
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            <Col span={24}>
              <Avatar
                src={imgAvatar}
                icon={<UserRound size={120} />}
                size={120}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Space direction="vertical" size={"small"}>
              <Card
                id="card-table"
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Medal
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                        Histórico de Avaliações
                      </Text>
                    </div>
                  </div>
                }
                bordered={false}
              >
                <Table
                  columns={ColumnsUserComparation(data)}
                  dataSource={data.reviews}
                  pagination={{ pageSize: 2, showSizeChanger: false }}
                  locale={{
                    emptyText: "Sem registro",
                  }}
                />
              </Card>
            </Space>
          </Row>
          <Divider />

          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Form.Item
                name="name"
                label={<span style={{ fontFamily: FONT }}>Aluno</span>}
              >
                <Input
                  disabled={true}
                  style={{ fontFamily: FONT }}
                  defaultValue={data.name}
                />
              </Form.Item>
            </Col>
            <Col span={outerWidth < 501 ? 24 : 6} style={{ marginTop: "10px" }}>
              <Form.Item
                name="reviewActual"
                label={
                  <span style={{ fontFamily: FONT }}>Avaliação Atual</span>
                }
                rules={[
                  {
                    required: true,
                    message: "É necessário informar a data da avaliação",
                  },
                ]}
              >
                <DatePicker
                  style={{ fontFamily: FONT, width: "100%" }}
                  placeholder={["", ""]}
                  format={dateFormat}
                  suffixIcon={null}
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col span={outerWidth < 501 ? 24 : 6} style={{ marginTop: "10px" }}>
              <Form.Item
                name="reviewComparation"
                label={
                  <span style={{ fontFamily: FONT }}>
                    Avaliação de Comparação
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "É necessário informar a data da avaliação",
                  },
                ]}
              >
                <DatePicker
                  style={{ fontFamily: FONT, width: "100%" }}
                  placeholder={["", ""]}
                  format={dateFormat}
                  suffixIcon={null}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Form.Item
                name="weight"
                label={<span style={{ fontFamily: FONT }}>Peso Atual</span>}
              >
                <InputNumber
                  disabled={true}
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Form.Item
                name="weightComparation"
                label={
                  <span style={{ fontFamily: FONT }}>Peso Comparação</span>
                }
              >
                <InputNumber
                  disabled={true}
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Text
                style={{
                  fontSize: "15px",
                  fontFamily: FONT,
                }}
              >
                Saltos
              </Text>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={outerWidth < 501 ? 24 : 8} style={{ marginTop: "10px" }}>
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowUpFromLine
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Counter Movement Jump - CMJ
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump: null,
                      jumpActual: data?.actualReview?.cmj,
                      jumpComparation: data?.lastReview?.cmj,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "CMJ Atual",
                  "CMJ Comparação",
                  10
                )}
              </Card>
            </Col>
            <Col span={outerWidth < 501 ? 24 : 8} style={{ marginTop: "10px" }}>
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowUpFromLine
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Squat Jump - SJ
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump: null,
                      jumpActual: data?.actualReview?.sj,
                      jumpComparation: data?.lastReview?.sj,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "SJ Atual",
                  "SJ Comparação",
                  10
                )}
              </Card>
            </Col>
            <Col span={outerWidth < 501 ? 24 : 8} style={{ marginTop: "10px" }}>
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowUpFromLine
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Drop Jump - DJ
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump: null,
                      jumpActual: data?.actualReview?.dj,
                      jumpComparation: data?.lastReview?.dj,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "DJ Atual",
                  "DJ Comparação",
                  10
                )}
              </Card>
            </Col>
          </Row>

          <Divider />
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Text
                style={{
                  fontSize: "15px",
                  fontFamily: FONT,
                }}
              >
                Força Relativa C-V Agachamento
              </Text>
            </Col>
          </Row>
        
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <BookUp2
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Carga x Velocidade
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartLiner(
                  [
                    {
                      load: data?.actualReview?.load1
                        ? data?.actualReview?.load1 - 10
                        : null,
                      vmpActual: null,
                      vmpComparation: null,
                    },

                    {
                      load: data?.actualReview?.load1,
                      vmpActual: data?.actualReview?.vmp1,
                      vmpComparation: data?.lastReview?.vmp1,
                    },
                    {
                      load: data?.actualReview?.load2,
                      vmpActual: data?.actualReview?.vmp2,
                      vmpComparation: data?.lastReview?.vmp2,
                    },
                    {
                      load: data?.actualReview?.load3,
                      vmpActual: data?.actualReview?.vmp3,
                      vmpComparation: data?.lastReview?.vmp3,
                    },
                    {
                      load: data?.actualReview?.load4,
                      vmpActual: data?.actualReview?.vmp4,
                      vmpComparation: data?.lastReview?.vmp4,
                    },
                    {
                      load: data?.actualReview?.load5,
                      vmpActual: data?.actualReview?.vmp5,
                      vmpComparation: data?.lastReview?.vmp5,
                    },
                    {
                      load: data?.actualReview?.load6,
                      vmpActual: data?.actualReview?.vmp6,
                      vmpComparation: data?.lastReview?.vmp6,
                    },

                    {
                      load: data?.actualReview?.load6
                        ? data?.actualReview?.load6 + 10
                        : null,
                      vmpActual: null,
                      vmpComparation: null,
                    },
                  ],
                  "load",
                  "vmpActual",
                  "vmpComparation",
                  "VMP Atual",
                  "VMP Comparação",
                  10
                )}
                
              </Card>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Força Absoluta Atual:
                  </Text>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                      fontWeight: "bold",
                    }}
                  >
                    {data?.actualReview?.absoluteStrength &&
                      " " + data?.actualReview?.absoluteStrength + "  KG"}
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Precisão Atual:
                  </Text>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                      fontWeight: "bold",
                    }}
                  >
                    {data?.actualReview?.rquad &&
                      " " +
                        data?.actualReview?.rquad +
                        levelRquad(data?.actualReview?.rquad)}
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Força Absoluta Comparação:
                  </Text>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                      fontWeight: "bold",
                    }}
                  >
                    {data?.lastReview?.absoluteStrength &&
                      " " + data?.lastReview?.absoluteStrength + "  KG"}
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Precisão Comparação:
                  </Text>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                      fontWeight: "bold",
                    }}
                  >
                    {data?.lastReview?.rquad &&
                      " " +
                        data?.lastReview?.rquad +
                        levelRquad(data?.lastReview?.rquad)}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <BookUp2
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Carga x Potência
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartLiner(
                  [
                    {
                      load: data?.actualReview?.load1
                        ? data?.actualReview?.load1 - 10
                        : null,
                      potenciaActual: null,
                      potenciaComparation: null,
                    },
                    {
                      load: data?.actualReview?.load1,
                      potenciaActual: data?.actualReview?.power1,
                      potenciaComparation: data?.lastReview?.power1,
                    },
                    {
                      load: data?.actualReview?.load2,
                      potenciaActual: data?.actualReview?.power2,
                      potenciaComparation: data?.lastReview?.power2,
                    },
                    {
                      load: data?.actualReview?.load3,
                      potenciaActual: data?.actualReview?.power3,
                      potenciaComparation: data?.lastReview?.power3,
                    },
                    {
                      load: data?.actualReview?.load4,
                      potenciaActual: data?.actualReview?.power4,
                      potenciaComparation: data?.lastReview?.power4,
                    },
                    {
                      load: data?.actualReview?.load5,
                      potenciaActual: data?.actualReview?.power5,
                      potenciaComparation: data?.lastReview?.power5,
                    },
                    {
                      load: data?.actualReview?.load6,
                      potenciaActual: data?.actualReview?.power6,
                      potenciaComparation: data?.lastReview?.power6,
                    },
                    {
                      load: data?.actualReview?.load6
                        ? data?.actualReview?.load6 + 10
                        : null,
                      potenciaActual: null,
                      potenciaComparation: null,
                    },
                  ],
                  "load",
                  "potenciaActual",
                  "potenciaComparation",
                  "Potência Atual",
                  "Potência Comparação",
                  100
                )}
              </Card>
            </Col>
          </Row>
          {data?.actualReview?.load1Ca != null && (
            <>
              <Divider />
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Força Relativa C-A
                  </Text>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col
                  span={outerWidth < 501 ? 24 : 12}
                  style={{ marginTop: "10px" }}
                >
                  <Card
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                          <BookUp2
                            size={18}
                            style={{
                              marginBottom: "-4px",
                              marginRight: "10px",
                            }}
                          />
                          <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                            Carga x Velocidade
                          </Text>
                        </div>
                      </div>
                    }
                    style={{ width: "100%" }}
                  >
                    {chartLiner(
                      [
                        {
                          load: data?.actualReview?.load1Ca
                            ? data?.actualReview?.load1Ca - 10
                            : null,
                          vmpActual: null,
                          vmpComparation: null,
                        },

                        {
                          load: data?.actualReview?.load1Ca,
                          vmpActual: data?.actualReview?.vmp1Ca,
                          vmpComparation: data?.lastReview?.vmp1Ca,
                        },
                        {
                          load: data?.actualReview?.load2Ca,
                          vmpActual: data?.actualReview?.vmp2Ca,
                          vmpComparation: data?.lastReview?.vmp2Ca,
                        },
                        {
                          load: data?.actualReview?.load3Ca,
                          vmpActual: data?.actualReview?.vmp3Ca,
                          vmpComparation: data?.lastReview?.vmp3Ca,
                        },
                        {
                          load: data?.actualReview?.load4Ca,
                          vmpActual: data?.actualReview?.vmp4Ca,
                          vmpComparation: data?.lastReview?.vmp4Ca,
                        },
                        {
                          load: data?.actualReview?.load5Ca,
                          vmpActual: data?.actualReview?.vmp5Ca,
                          vmpComparation: data?.lastReview?.vmp5Ca,
                        },
                        {
                          load: data?.actualReview?.load6Ca,
                          vmpActual: data?.actualReview?.vmp6Ca,
                          vmpComparation: data?.lastReview?.vmp6Ca,
                        },

                        {
                          load: data?.actualReview?.load6Ca
                            ? data?.actualReview?.load6Ca + 10
                            : null,
                          vmpActual: null,
                          vmpComparation: null,
                        },
                      ],
                      "load",
                      "vmpActual",
                      "vmpComparation",
                      "VMP Atual",
                      "VMP Comparação",
                      10
                    )}
                  </Card>
                </Col>
                <Col
                  span={outerWidth < 501 ? 24 : 12}
                  style={{ marginTop: "10px" }}
                >
                  <Card
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                          <BookUp2
                            size={18}
                            style={{
                              marginBottom: "-4px",
                              marginRight: "10px",
                            }}
                          />
                          <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                            Carga x Potência
                          </Text>
                        </div>
                      </div>
                    }
                    style={{ width: "100%" }}
                  >
                    {chartLiner(
                      [
                        {
                          load: data?.actualReview?.load1Ca
                            ? data?.actualReview?.load1Ca - 10
                            : null,
                          potenciaActual: null,
                          potenciaComparation: null,
                        },
                        {
                          load: data?.actualReview?.load1Ca,
                          potenciaActual: data?.actualReview?.power1Ca,
                          potenciaComparation: data?.lastReview?.power1Ca,
                        },
                        {
                          load: data?.actualReview?.load2Ca,
                          potenciaActual: data?.actualReview?.power2Ca,
                          potenciaComparation: data?.lastReview?.power2Ca,
                        },
                        {
                          load: data?.actualReview?.load3Ca,
                          potenciaActual: data?.actualReview?.power3Ca,
                          potenciaComparation: data?.lastReview?.power3Ca,
                        },
                        {
                          load: data?.actualReview?.load4Ca,
                          potenciaActual: data?.actualReview?.power4Ca,
                          potenciaComparation: data?.lastReview?.power4Ca,
                        },
                        {
                          load: data?.actualReview?.load5Ca,
                          potenciaActual: data?.actualReview?.power5Ca,
                          potenciaComparation: data?.lastReview?.power5Ca,
                        },
                        {
                          load: data?.actualReview?.load6Ca,
                          potenciaActual: data?.actualReview?.power6Ca,
                          potenciaComparation: data?.lastReview?.power6Ca,
                        },
                        {
                          load: data?.actualReview?.load6Ca
                            ? data?.actualReview?.load6Ca + 10
                            : null,
                          potenciaActual: null,
                          potenciaComparation: null,
                        },
                      ],
                      "load",
                      "potenciaActual",
                      "potenciaComparation",
                      "Potência Atual",
                      "Potência Comparação",
                      100
                    )}
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {data?.actualReview?.load1CvNew != null && (
            <>
              <Divider />
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Força Relativa C-V {data?.actualReview?.nameCvNew}
                  </Text>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col
                  span={outerWidth < 501 ? 24 : 12}
                  style={{ marginTop: "10px" }}
                >
                  <Card
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                          <BookUp2
                            size={18}
                            style={{
                              marginBottom: "-4px",
                              marginRight: "10px",
                            }}
                          />
                          <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                            Carga x Velocidade
                          </Text>
                        </div>
                      </div>
                    }
                    style={{ width: "100%" }}
                  >
                    {chartLiner(
                      [
                        {
                          load: data?.actualReview?.load1CvNew
                            ? data?.actualReview?.load1CvNew - 10
                            : null,
                          vmpActual: null,
                          vmpComparation: null,
                        },

                        {
                          load: data?.actualReview?.load1CvNew,
                          vmpActual: data?.actualReview?.vmp1CvNew,
                          vmpComparation: data?.lastReview?.vmp1CvNew,
                        },
                        {
                          load: data?.actualReview?.load2CvNew,
                          vmpActual: data?.actualReview?.vmp2CvNew,
                          vmpComparation: data?.lastReview?.vmp2CvNew,
                        },
                        {
                          load: data?.actualReview?.load3CvNew,
                          vmpActual: data?.actualReview?.vmp3CvNew,
                          vmpComparation: data?.lastReview?.vmp3CvNew,
                        },
                        {
                          load: data?.actualReview?.load4CvNew,
                          vmpActual: data?.actualReview?.vmp4CvNew,
                          vmpComparation: data?.lastReview?.vmp4CvNew,
                        },
                        {
                          load: data?.actualReview?.load5CvNew,
                          vmpActual: data?.actualReview?.vmp5CvNew,
                          vmpComparation: data?.lastReview?.vmp5CvNew,
                        },
                        {
                          load: data?.actualReview?.load6CvNew,
                          vmpActual: data?.actualReview?.vmp6CvNew,
                          vmpComparation: data?.lastReview?.vmp6CvNew,
                        },

                        {
                          load: data?.actualReview?.load6CvNew
                            ? data?.actualReview?.load6CvNew + 10
                            : null,
                          vmpActual: null,
                          vmpComparation: null,
                        },
                      ],
                      "load",
                      "vmpActual",
                      "vmpComparation",
                      "VMP Atual",
                      "VMP Comparação",
                      10
                    )}
                  </Card>
                </Col>
                <Col
                  span={outerWidth < 501 ? 24 : 12}
                  style={{ marginTop: "10px" }}
                >
                  <Card
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                          <BookUp2
                            size={18}
                            style={{
                              marginBottom: "-4px",
                              marginRight: "10px",
                            }}
                          />
                          <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                            Carga x Potência
                          </Text>
                        </div>
                      </div>
                    }
                    style={{ width: "100%" }}
                  >
                    {chartLiner(
                      [
                        {
                          load: data?.actualReview?.load1CvNew
                            ? data?.actualReview?.load1CvNew - 10
                            : null,
                          potenciaActual: null,
                          potenciaComparation: null,
                        },
                        {
                          load: data?.actualReview?.load1CvNew,
                          potenciaActual: data?.actualReview?.power1CvNew,
                          potenciaComparation: data?.lastReview?.power1CvNew,
                        },
                        {
                          load: data?.actualReview?.load2CvNew,
                          potenciaActual: data?.actualReview?.power2CvNew,
                          potenciaComparation: data?.lastReview?.power2CvNew,
                        },
                        {
                          load: data?.actualReview?.load3CvNew,
                          potenciaActual: data?.actualReview?.power3CvNew,
                          potenciaComparation: data?.lastReview?.power3CvNew,
                        },
                        {
                          load: data?.actualReview?.load4CvNew,
                          potenciaActual: data?.actualReview?.power4CvNew,
                          potenciaComparation: data?.lastReview?.power4CvNew,
                        },
                        {
                          load: data?.actualReview?.load5CvNew,
                          potenciaActual: data?.actualReview?.power5CvNew,
                          potenciaComparation: data?.lastReview?.power5CvNew,
                        },
                        {
                          load: data?.actualReview?.load6CvNew,
                          potenciaActual: data?.actualReview?.power6CvNew,
                          potenciaComparation: data?.lastReview?.power6CvNew,
                        },
                        {
                          load: data?.actualReview?.load6CvNew
                            ? data?.actualReview?.load6CvNew + 10
                            : null,
                          potenciaActual: null,
                          potenciaComparation: null,
                        },
                      ],
                      "load",
                      "potenciaActual",
                      "potenciaComparation",
                      "Potência Atual",
                      "Potência Comparação",
                      100
                    )}
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <Divider />
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowUpFromLine
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Sprint Linear
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump:
                        data?.actualReview?.sprintLinearDate +
                        " - " +
                        data?.lastReview?.sprintLinearDate,
                      jumpActual: data?.actualReview?.sprintLinear,
                      jumpComparation: data?.lastReview?.sprintLinear,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "Linear Atual",
                  "Linear Comparação",
                  10
                )}
              </Card>
            </Col>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowUpFromLine
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Cod Sprint
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump:
                        data?.actualReview?.codSprintDate +
                        " - " +
                        data?.lastReview?.codSprintDate,
                      jumpActual: data?.actualReview?.codSprint,
                      jumpComparation: data?.lastReview?.codSprint,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "Cod Atual",
                  "Cod Comparação",
                  10
                )}
              </Card>
            </Col>
          </Row>
        </Form>
        <DrawerApp
          open={open}
          onClose={onClose}
          title={"Editar Avaliação"}
          body={formNewUserReview(
            formRef,
            imgAvatar,
            reviewCa,
            setReviewCa,
            false,
            reviewCvNew,
            setReviewCvNew
          )}
          size={"large"}
          actionSave={handleEditReview}
          width={outerWidth < 501 ? "100%" : "65%"}
        />
      </Spin>
    </>
  );
};
export default FormUserComparation;
