import { Avatar } from "antd";
import { UserRound } from "lucide-react";

export const ColumnsUser = () => {
  const table = [
    {
      title: "Classificação",
      dataIndex: "position",
      textWrap: "word-break",
      ellipsis: true,
      width: "15%",
      render: (item, record, index) => <>{index + 1}º </>,
    },
  ];
  const tableDefault = [
    {
      title: "Foto",
      dataIndex: "image",
      width: "15%",
      render: (text, row) => {
        return (
          <Avatar src={row.img} icon={<UserRound size={50} />} size={50} />
        );
      },
    },
  ];

  if (innerWidth > 500) {
    table.push(...tableDefault);
  }

  table.push(
    {
      title: "Nome",
      dataIndex: "name",
      textWrap: "word-break",
      ellipsis: true,
      width: "15%",
    },
    {
      title: "Idade",
      dataIndex: "age",
      textWrap: "word-break",
      ellipsis: true,
      width: "15%",
      render: (text, row) => {
        return text + " anos";
      },
    },
    {
      title: "Pontuação",
      dataIndex: "point",
      textWrap: "word-break",
      ellipsis: true,
      width: "15%",
    }
  );
  return table;
};
