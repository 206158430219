import Services from "api/ProjectApiConsumer";

export function getUsersReviews(obj) {
  return Services.reviewService.getUsersReviews(undefined, obj);
}

export function getUsersReviewsRanking(obj) {
  return Services.reviewService.getUsersReviewsRanking(undefined, obj);
}


export function createReview(obj) {
  return Services.reviewService.createReview(obj);
}
export function putReview(obj) {
  return Services.reviewService.putReview(obj);
}
export function deleteReview(id) {
  return Services.reviewService.deleteReview(undefined, undefined, id);
}

export function getReviewUserById(id) {
  return Services.reviewService.getReviewUserById(undefined, undefined, id);
}
export function getReviewUserOpenById(id) {
  return Services.reviewService.getReviewUserOpenById(undefined, undefined, id);
}
export function createSprint(obj) {
  return Services.reviewService.createSprint(obj);
}
export function getSprintUserById(id) {
  return Services.reviewService.getSprintUserById(undefined, undefined, id);
}

export function putSprint(obj) {
  return Services.reviewService.putSprint(obj);
}
export function deleteSprint(id) {
  return Services.reviewService.deleteSprint(undefined, undefined, id);
}

