import RestAPI from "./RestApiConsumer";
import { API_BASE_URL } from "constants/index";

export const api = new RestAPI(API_BASE_URL);

//Login Controller
api.addEndpoint("login", undefined, "POST", false);
api.addRichParametersEndpoint(
  "users/generate-code",
  "generateCode",
  "POST",
  false
);
api.addEndpoint("users/new-password", "newPassword", "POST", false);

export const userService = {
  login: api.endpoints.login,
  generateCode: api.endpoints.generateCode,
  newPassword: api.endpoints.newPassword,
};

//User dashboard

api.addEndpoint("dashboard", "getCashFlowByType", "POST", true);
api.addEndpoint("dashboard/type", "getCashFlowByTypeToDashboard", "POST", true);
api.addEndpoint("dashboard/balance", "getCashFlowBalance", "POST", true);
api.addEndpoint(
  "dashboard/balance-data",
  "getCashFlowBalanceData",
  "POST",
  true
);
api.addEndpoint("cash-flow/kpi", "getKpiCashFlow", "POST", true);
api.addEndpoint("cash-flow/kpi/company", "getKpiCashFlowCompany", "POST", true);
api.addEndpoint("cash-flow/banks", "getBanks", "GET", true);

api.addEndpoint("dashboard/now", "getManagerialAmount", "GET", true);
api.addEndpoint("dashboard/accounting", "getManagerialAccount", "GET", true);
api.addEndpoint("dashboard/cash", "getManagerialCash", "GET", true);

//Admin company

api.addEndpoint("companies", "getCompanies", "GET", true);
api.addEndpoint("companies", "saveCompany", "POST", true);
api.addEndpoint("companies", "putCompany", "PUT", true);
api.addRichParametersEndpoint("companies", "deleteCompany", "DELETE", true);
api.addEndpoint("companies/category", "getCompanyCategories", "GET", true);
api.addEndpoint("companies/category", "saveCompanyCategories", "POST", true);
api.addEndpoint("companies/category", "putCompanyCategories", "PUT", true);
api.addRichParametersEndpoint(
  "companies/category",
  "deleteCompanyCategories",
  "DELETE",
  true
);
api.addEndpoint(
  "companies/mapping-cash-flow",
  "getCompanyMappingCashFlow",
  "GET",
  true
);
api.addEndpoint(
  "companies/mapping-cash-flow",
  "saveCompanyMappingCashFlow",
  "POST",
  true
);
api.addRichParametersEndpoint(
  "companies/category/company",
  "getCompanyCategoriesByCompanyId",
  "GET",
  true
);
api.addRichParametersEndpoint(
  "companies/mapping-cash-flow",
  "deleteCompanyMappingCashFlow",
  "DELETE",
  true
);
api.addRichParametersEndpoint(
  "companies/mapping-cash-flow/category",
  "deleteCompanyMappingCashFlowCategory",
  "DELETE",
  true
);
api.addEndpoint(
  "companies/mapping-cash-flow",
  "putCompanyMappingCashFlow",
  "PUT",
  true
);
api.addEndpoint(
  "companies/mapping-income",
  "getCompanyMappingIncome",
  "GET",
  true
);
api.addEndpoint(
  "companies/mapping-income",
  "saveCompanyMappingIncome",
  "POST",
  true
);

api.addRichParametersEndpoint(
  "companies/mapping-income",
  "deleteCompanyMappingIncome",
  "DELETE",
  true
);
api.addRichParametersEndpoint(
  "companies/mapping-income/category",
  "deleteCompanyMappingIncomeCategory",
  "DELETE",
  true
);
api.addEndpoint(
  "companies/mapping-income",
  "putCompanyMappingIncome",
  "PUT",
  true
);

api.addEndpoint("cash-flow", "getCashFlow", "POST", true);
api.addEndpoint("income", "getIncome", "POST", true);

api.addRichParametersEndpoint(
  "companies/mapping-cash-flow/group",
  "getCompanyGroupByCompanyId",
  "GET",
  true
);

api.addEndpoint("companies/banks", "getAllBanks", "GET", true);
api.addEndpoint("companies/banks", "saveBank", "POST", true);
api.addEndpoint("companies/banks", "putBank", "PUT", true);

api.addRichParametersEndpoint(
  "companies/banks",
  "deleteCompanyBank",
  "DELETE",
  true
);
api.addEndpoint("companies/status", "getStatus", "GET", true);
api.addRichParametersEndpoint(
  "companies/integration",
  "integrationImport",
  "POST",
  true
);
api.addRichParametersEndpoint(
  "companies/plan-cash-flow",
  "integrationImportPlan",
  "POST",
  true
);

api.addEndpointWithParams(
  "cash-flow/cost-center-amount",
  "getCostCenters",
  "GET",
  true
);

//Users

api.addEndpointWithParams("users", "getUsers", "GET", true);
api.addEndpoint("users", "saveUser", "POST", true);
api.addEndpoint("users", "putUser", "PUT", true);
api.addRichParametersEndpoint("users", "deleteUser", "DELETE", true);
api.addRichParametersEndpoint("users", "getById", "GET", true);

//Reviews
api.addEndpointWithParams("reviews", "getUsersReviews", "GET", true);
api.addEndpointWithParams("reviews/ranking", "getUsersReviewsRanking", "GET", true);
api.addEndpoint("reviews", "createReview", "POST", true);
api.addEndpoint("reviews", "putReview", "PUT", true);
api.addRichParametersEndpoint("reviews", "deleteReview", "DELETE", true);
api.addRichParametersEndpoint("reviews", "getReviewUserById", "GET", true);
api.addEndpoint("reviews/sprint", "createSprint", "POST", true);
api.addRichParametersEndpoint(
  "reviews/sprint",
  "getSprintUserById",
  "GET",
  true
);api.addRichParametersEndpoint(
  "reviews/sprint/open",
  "getReviewUserOpenById",
  "GET",
  true
);

api.addEndpoint("reviews/sprint", "putSprint", "PUT", true);
api.addRichParametersEndpoint("reviews/sprint", "deleteSprint", "DELETE", true);
//Admin import

api.addEndpoint("import", "getFiles", "GET", true);
api.addRichParametersEndpoint("import", "deleteFileImported", "DELETE", true);

//Admin cash flow

api.addRichParametersEndpoint(
  "financial-admin/banks",
  "getBankByClient",
  "GET",
  true
);

api.addEndpoint("financial-admin/cash-flow", "getCashFlowAdmin", "POST", true);
api.addEndpoint("financial-admin/income", "getIncomeAdmin", "POST", true);

api.addEndpoint("income/v2", "getIncomeV2", "POST", true);
api.addEndpoint(
  "cash-flow/cash-flow-category",
  "getCashFlowByFilter",
  "POST",
  true
);

export const dashBoardService = {
  getCashFlowByType: api.endpoints.getCashFlowByType,
  getCashFlowBalance: api.endpoints.getCashFlowBalance,
  getKpiCashFlow: api.endpoints.getKpiCashFlow,
  getKpiCashFlowCompany: api.endpoints.getKpiCashFlowCompany,
  getManagerialAmount: api.endpoints.getManagerialAmount,
  getManagerialAccount: api.endpoints.getManagerialAccount,
  getManagerialCash: api.endpoints.getManagerialCash,
  getBanks: api.endpoints.getBanks,
  getCashFlowByTypeToDashboard: api.endpoints.getCashFlowByTypeToDashboard,
  getCashFlowBalanceData: api.endpoints.getCashFlowBalanceData,
};
export const companyService = {
  getCompanies: api.endpoints.getCompanies,
  saveCompany: api.endpoints.saveCompany,
  putCompany: api.endpoints.putCompany,
  deleteCompany: api.endpoints.deleteCompany,
  getCompanyCategories: api.endpoints.getCompanyCategories,
  saveCompanyCategories: api.endpoints.saveCompanyCategories,
  putCompanyCategories: api.endpoints.putCompanyCategories,
  deleteCompanyCategories: api.endpoints.deleteCompanyCategories,
  getCompanyMappingCashFlow: api.endpoints.getCompanyMappingCashFlow,
  saveCompanyMappingCashFlow: api.endpoints.saveCompanyMappingCashFlow,
  getCompanyCategoriesByCompanyId:
    api.endpoints.getCompanyCategoriesByCompanyId,
  deleteCompanyMappingCashFlow: api.endpoints.deleteCompanyMappingCashFlow,
  deleteCompanyMappingCashFlowCategory:
    api.endpoints.deleteCompanyMappingCashFlowCategory,
  putCompanyMappingCashFlow: api.endpoints.putCompanyMappingCashFlow,
  getCompanyMappingIncome: api.endpoints.getCompanyMappingIncome,
  saveCompanyMappingIncome: api.endpoints.saveCompanyMappingIncome,
  deleteCompanyMappingIncome: api.endpoints.deleteCompanyMappingIncome,
  deleteCompanyMappingIncomeCategory:
    api.endpoints.deleteCompanyMappingIncomeCategory,
  putCompanyMappingIncome: api.endpoints.putCompanyMappingIncome,
  getCashFlow: api.endpoints.getCashFlow,
  getIncome: api.endpoints.getIncome,
  getCompanyGroupByCompanyId: api.endpoints.getCompanyGroupByCompanyId,
  getAllBanks: api.endpoints.getAllBanks,
  saveBank: api.endpoints.saveBank,
  deleteCompanyBank: api.endpoints.deleteCompanyBank,
  getStatus: api.endpoints.getStatus,
  putBank: api.endpoints.putBank,
  integrationImport: api.endpoints.integrationImport,
  getCostCenters: api.endpoints.getCostCenters,
  integrationImportPlan: api.endpoints.integrationImportPlan,
};
//user

export const userAdminService = {
  getUsers: api.endpoints.getUsers,
  saveUser: api.endpoints.saveUser,
  putUser: api.endpoints.putUser,
  deleteUser: api.endpoints.deleteUser,
  getById: api.endpoints.getById,
};

//review user

export const reviewService = {
  getUsersReviews: api.endpoints.getUsersReviews,
  createReview: api.endpoints.createReview,
  putReview: api.endpoints.putReview,
  deleteReview: api.endpoints.deleteReview,
  getReviewUserById: api.endpoints.getReviewUserById,
  createSprint: api.endpoints.createSprint,
  getSprintUserById: api.endpoints.getSprintUserById,
  putSprint: api.endpoints.putSprint,
  deleteSprint: api.endpoints.deleteSprint,
  getUsersReviewsRanking: api.endpoints.getUsersReviewsRanking,
  getReviewUserOpenById: api.endpoints.getReviewUserOpenById,
};

//import cash flow

export const importAdminService = {
  getFiles: api.endpoints.getFiles,
  deleteFileImported: api.endpoints.deleteFileImported,
};

export const financialAdminService = {
  getBankByClient: api.endpoints.getBankByClient,
  getCashFlowAdmin: api.endpoints.getCashFlowAdmin,
  getIncomeAdmin: api.endpoints.getIncomeAdmin,
};

export const incomeService = {
  getIncomeV2: api.endpoints.getIncomeV2,
};
export const cashFlowService = {
  getCashFlowByFilter: api.endpoints.getCashFlowByFilter,
};
const Services = {
  userService,
  dashBoardService,
  companyService,
  userAdminService,
  importAdminService,
  financialAdminService,
  incomeService,
  cashFlowService,
  reviewService,
};

export default Services;
